import React from 'react';
import ProductList from './components/ProductList'; // Adjust the path if necessary

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Welcome to the Product Store</h1>
            </header>
            <main>
                <ProductList />
            </main>
        </div>
    );
}

export default App;
